import React from 'react';

import CalendarHeatmap from 'react-calendar-heatmap';
import { Tooltip as ReactTooltip } from 'react-tooltip'

import './heatmap-styles.scss';

const today = new Date();

const CalendarHeat = () => {
  const randomValues = getRange(60).map(index => {
    return {
      date: shiftDate(today, -index),
      count: getRandomInt(1, 3),
    }
  })

  return (
    <div style={{ paddingRight: '40px' }}>
      <CalendarHeatmap
        startDate={shiftDate(today, -31)}
        endDate={today}
        values={randomValues}
        classForValue={value => {
          if (!value) {
            return 'color-empty';
          }
          return `color-github-${value.count}`;
        }}
        gutterSize={4}
        showWeekdayLabels={true}
        showMonthLabels={false}
        tooltipDataAttrs={value => {
          return {
            'data-tip': `${value.date.toISOString().slice(0, 10)} has count: ${
              value.count
            }`,
          };
        }}
        onClick={value => alert(`Clicked on value with count: ${value.count}`)}
      />
      <ReactTooltip />
    </div>
  );
}

function shiftDate(date, numDays) {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + numDays);
  return newDate;
}

function getRange(count) {
  return Array.from({ length: count }, (_, i) => i);
}

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default CalendarHeat