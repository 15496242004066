import React from "react"

import {
  BarSeries,
  VerticalGridLines,
  HorizontalGridLines,
  HorizontalBarSeries,
  HorizontalBarSeriesCanvas,
  VerticalBarSeries,
  XYPlot,
  XAxis,
  YAxis,
} from 'react-vis'
import 'react-vis/dist/main.scss'

const BarChart = ({
  data = [
    {y: 'chill', x: 100},
    {y: 'fun', x: 60},
    {y: 'affordable', x: 40},
    {y: 'local', x: 20},
    {y: 'community', x: 10}
  ],
  height = 240,
  width = 220,
  showXGrid = false,
  showYGrid = false,
  ...props
}) =>  {

    return (
    <div className="chart">
      <XYPlot
        width={width}
        height={height}
        yType="ordinal"
        margin={{left: 60, right: 10, top: 10, bottom: 40}}
      >
        {showXGrid ? <HorizontalGridLines /> : null}
        {showYGrid ? <VerticalGridLines /> : null}

        <YAxis
          style={{ text: { fontSize: 8} }} />
        <XAxis />

        <HorizontalBarSeries data={data} />
      </XYPlot>
    </div>
  )
}

export default BarChart;